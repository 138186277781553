import React from "react"
import { useIsMobile } from "../utils"
import { SEO } from '../components/seo';

const FAQSection = ({ title, children }) => {
  return (
    <div>
      <div className="body2">{title}</div>
      <br />
      <div className="body1" style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
        {children}
      </div>
      <br />
    </div>
  )
}

const FAQ = () => {
  const { isMobile } = useIsMobile()

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-around",
          paddingTop: 32,
          marginBottom: 64,
          marginTop: 64,
        }}
      >
        <div
          className="title1"
          style={{
            padding: `0 24px`,
            display: "flex",
            flexDirection: "column",
            gap: 48,
          }}
        >
          FAQ
          <img
            src="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/faq-pic1.jpg"
            style={{
              maxHeight: 700,
              objectFit: "cover",
              marginBottom: 24,
            }}
          />
        </div>
        <div style={{ maxWidth: 600, padding: `16px 24px` }}>
          <FAQSection title="1. 運送方式">
            Cooperation Coffee
            提供新鮮烘焙的咖啡給全球喜歡我們咖啡的朋友，咖啡在烘焙的 48
            小時內包裝並發貨，可以選擇自己想到抵達的時間以及所熟悉的郵寄方式。
          </FAQSection>
          <FAQSection title="2. 訂購目的地與條件">
            <div>
              在某些國家及地區，我們會受到當地進口咖啡法規與其他海關法規的限制，請自行了解您當地區域的運輸或海關報關的條件與要求，所有關稅和稅費均由您負責，包括除區域機構和當局要求的購買發票之外的任何文件。
            </div>
            <br />
            <div>
              確保您包含當地機構和當局要求的完整和正確的信息。如果包裹因信息錯誤或缺失而被卡住，我們保留拒絕重新發貨或退款的權力。
            </div>
            <br />
            <div>
              如果因為地址信息丟失或錯誤或拒絕交付而導致訂單退回給我們，我們保留向您收取包裹運費的權力。如果您在退貨時要求重新裝運產品，我們保留向您收取重新裝運的運費的權力。
            </div>
          </FAQSection>
          <FAQSection title="3. 運送與抵達">
            <div>
              所有的訂單出貨均有追蹤編號提供查詢，我們會在出貨的同時，將追蹤編號寄件至您的電子信箱。運送台灣本地的貨物有中華郵政、便利商店店到店可以做選擇。全球運送由中華郵政運送。
            </div>
            <br />
            <div>
              包裹運送時間以包裹被提取開始計算，而不是在完成訂單時開始，訂單僅在週二至週五的工作時間內處理及出貨，如遇到國定假日將會提早或順延出貨時間。
            </div>
          </FAQSection>
          <FAQSection title="4. 運送與退貨">
            <div>
              運費的費用會取決在您所買的重量、選擇何種運輸方式以及需要運送到的地區而有所差別，我們會在最後結帳的頁面，提供不同的運輸方式以及運費供您做選擇。
            </div>
            <br />
            <div>
              退貨：如果所選擇的咖啡風味不是自己所喜歡的，們很樂意再次推薦其他的咖啡豆給您，如需退貨，可以直接聯絡我們，我們會幫您處理。
            </div>
          </FAQSection>
          <FAQSection title="5. 付款">
            <div>
              合作咖啡網站目前只提供信用卡付款，如有任何付款上的問題，歡迎寄信詢問我們。
            </div>
          </FAQSection>
          <FAQSection title="6. 大量採購">
            <div>
              我們嚴格且重視產品的品質，以及風味的測試，希望帶給所有客戶一個優質的咖啡體驗，我們非常樂意與喜歡我們咖啡的企業合作。
            </div>
            <br />
            <div>
              如果需要大量批發，可以先透過單一訂閱的方式，品嚐我們的咖啡豆，或者直接與我們聯絡。
            </div>
          </FAQSection>
        </div>
        <div />
      </div>
    </div>
  )
}
export default FAQ
export const Head = () => (
  <SEO
    title="FAQ"
    description="Cooperation Coffee 是一家位於台灣的咖啡公司。合作咖啡成立於 2017 年，我們從網路販售咖啡豆開始。一直到 2019 年我們有了第一家實體的咖啡店，店鋪座落在台灣的台中市南區。"
  />
)
